import * as React from "react"
import Search from "../components/Search"

const SearchPage = () => {
  return (
    <main>
      <Search />
    </main>
  )
}

export default SearchPage
